<template>
  <div class="home-index">
    <div class="home-login">
      <div class="w50 bg-i right-l-h">
        <img
          class="img-login-logo"
          src="./../../../public/img/gohrnew.png"
        />
        <div class="info-right">
          <h2>Xush kelibsiz</h2>

          <router-link class="lick-login2" :to="{ name: 'login2' }">
            <div>
              ID ORQALI KIRISH
              <i class="el-icon-d-arrow-right"></i>
            </div>
          </router-link>
          <input
            class="op-0"
            type="text"
            id="fname"
            name="fname"
            ref="inputId"
            style="width:0; height:0; position:absolute; top:50%;left:50%; z-index:-5"
            v-model="staffId"
          />
        </div>
        <svg
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
          class="absolute inset-0 pointer-events-none ng-tns-c187-29"
          style="color:#135196"
        >
          <g
            fill="none"
            stroke="currentColor"
            stroke-width="100"
            class="text-gray-700 opacity-25 ng-tns-c187-29"
          >
            <circle r="234" cx="196" cy="23" class="ng-tns-c187-29"></circle>
            <circle r="234" cx="790" cy="491" class="ng-tns-c187-29"></circle>
          </g>
        </svg>
        <svg
          class="posSvg"
          viewBox="0 0 220 192"
          width="220"
          height="192"
          fill="none"
          style="color:#135196"
        >
          <defs class="ng-tns-c187-29">
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
              class="ng-tns-c187-29"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                fill="currentColor"
                class="ng-tns-c187-29"
              ></rect>
            </pattern>
          </defs>
          <rect
            width="220"
            height="192"
            fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
            class="ng-tns-c187-29"
          ></rect>
        </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="600" height="700"
          class="absolute  pointer-events-none sircle"
          style="color:#135196">
          <circle cx="200" cy="420" r="300" fill="none" stroke="#135196" stroke-width="170"/>  
        </svg>
        <img class="img-qr" src="./../../../public/img/scanner.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { setItem, removeItem, getItem } from "@/utils/storage";
export default {
  name: "login",
  // components: { homeTop }
  data() {
    return {
      activeName: "callTab",
      calculation: "",
      input2: "",
      activeIconPhone: false,
      callingModal: false,
      addMic: false,
      noneBlock: false,
      miniPage: false,
      noneIcon: false,
      staffId: null,
    };
  },
  mounted() {
    removeItem("userId");
    this.$refs.inputId.focus();
  },
  watch: {
    staffId() {
      if (this.staffId.toString().length == 5) {
        const loading = this.$loading({
          customClass: "login-loading",
          lock: true,
          text: "Загрузка",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        var is_loading = true;
        if (this.staffId != null && this.staffId.toString().length == 5) {
          this.updateList(this.staffId)
            .then((res) => {
              loading.close();
              is_loading = false;
              if (res.status == 200) {
                 setItem("userIdTest", this.calculation);
                  let route = this.$router.push({
                    name: "login_auth"
                  });
                  setItem('additional_work_reason_id', null);
                  setItem('performance_type', null),
                  setItem('work_time', 
                              [
                                moment().format('YYYY-MM-DD HH:mm:ss'), 
                                moment().format('YYYY-MM-DD HH:mm:ss')
                              ]);
                  console.log(moment().format('YYYY-MM-DD HH:mm:ss'));
                  this.calculation = "";
                  this.staffId = null;
                  window.open(route.href, "_self");
              } else {
                // loading.close();
                // is_loading = false;

                this.staffId = null;
              }
            })
            .catch((err) => {
              loading.close();
              is_loading = false;
            });
        } else {
          this.staffId = null;
        }

        loading.close();
        is_loading = false;
      }
      if (this.staffId.toString().length > 5) {
        this.staffId = null;
      }
    },
  },
  methods: {
    ...mapActions({
      updateList: "assent/index",
    }),
    handleClose(done) {
      this.$confirm("Are you sure to close this dialog?")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },

    /****************Phone Numer***********************/
    append(value) {
      this.calculation += value.toString();
    },
    del() {
      if (this.calculation.toString().length > 1) {
        this.calculation = this.calculation
          .toString()
          .substring(0, this.calculation.toString().length - 1);
      } else {
        this.calculation = "";
      }
    },
    /****************End Phone Numer***********************/

    /****************icon Phone Icon Modal***********************/
    iconPhone(e) {
      this.activeIconPhone = true;
      this.noneIcon = true;
    },
    closeIconPhone(e) {
      this.activeIconPhone = false;
      this.noneIcon = false;
    },
    /****************icon Phone Icon Modal***********************/

    /****************icon Phone Icon Modal***********************/
    // callingClick(e) {
    //     this.callingModal = true;
    //     this.activeIconPhone = false;
    //     this.miniPage = false;
    // },
    // closeCallingClick(e) {
    //     this.callingModal = false;
    //     this.activeIconPhone = true;
    //     this.noneBlock = false;
    // },
  },
};
</script>
<style lang="css">
.home-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.sircle{
  bottom:-250px; 
  left:0;
}
.w50 {
  width: 100%;
  height: 100vh;
  position: relative;
}
.bg-i {
  background: #1e293b;
}
.bg-i svg {
  color: #2f3c50;
}
.posSvg {
  position: absolute;
  top: 1px;
  right: 1px;
}
.inset-0 {
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
}
.absolute,
.\!absolute {
  position: absolute !important;
}
.right-l-h {
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-right {
  z-index: 2;
  position: relative;
  /* padding: 20px; */
  color: #fff;
}
.info-right h2 {
  font-size: 3rem !important;
}
.pin-code {
  padding: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.img-qr {
  position: absolute;
  right: -30px;
  width: 400px;
  bottom: 100px;
}
.img-login-logo {
  width: 200px;
  position: absolute;
  top: 30px;
  z-index: 9;
  left: 50px;
}
.op-0 {
  opacity: 0;
}
.lick-login2 {
  /* background-color: #00c200; */
  font-weight: 300;
  border-radius: 5px;
  color: #fff;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
</style>
